import React, { FC } from "react";
import { Button } from "./shadcn/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/shadcn/dialog";
import { Input } from "./shadcn/input";

interface JsonEditorActionsProps {
  onSave: (e: React.MouseEvent, commitMessage: string) => void;
  onPublish: (e: React.MouseEvent) => void;
  hasCommits: boolean;
}

export const JsonEditorActions: FC<JsonEditorActionsProps> = ({
  onSave,
  onPublish,
  hasCommits,
}) => {
  const [commitMessage, setCommitMessage] = React.useState(
    "Update file content"
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCommitMessage(e.target.value);
  };

  const handleSaveClick = (e: React.MouseEvent) => {
    onSave(e, commitMessage);
  };
  return (
    <div className="sticky bottom-1 w-full flex justify-center md:justify-between items-center rounded backdrop-blur-sm border border-gray-200 bg-white/80 mt-4 p-4">
      <span className="max-w-xl hidden md:block text-xs">
        After clicking this button, a commit of all changes and a pull request
        will be automatically created. To activate the changes, you must approve
        this pull request on GitHub.
      </span>
      <div className="flex gap-2">
        <Dialog>
          <DialogTrigger asChild>
            <Button>Save</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Save all changes</DialogTitle>
              <DialogDescription>
                  This action will commit to GitHub with all the changes you
                  made to the working (not master) branch.
                Below you can change the name of the commit
              </DialogDescription>
            </DialogHeader>
            <Input value={commitMessage} onChange={handleInputChange} />
            <Button onClick={handleSaveClick}>Save</Button>
          </DialogContent>
        </Dialog>
        <Button onClick={onPublish} disabled={!hasCommits}>
          Publish
        </Button>
      </div>
    </div>
  );
};
