import axios from "axios";
import { LocalesList } from "../types/Locales";

export const getLocalesList = async (
  owner: string,
  repositoryName: string
): Promise<{ data: LocalesList[]; hasCommits: boolean } | undefined> => {
  try {
    const response = await axios.get(
      `/locale/locales/${owner}/${repositoryName}`,
      { withCredentials: true }
    );
    const jsonData = response.data.json ?? [];
    const hasCommits = response.data.hasCommits ?? false;
    return { data: jsonData as LocalesList[], hasCommits };
  } catch (error) {
    console.log("Get repository locales error:", error);
    return undefined;
  }
};

export const updateLocalesList = async (
  owner: string,
  repositoryName: string
) => {
  try {
    const response = await axios.post(
      `/locale/update/${owner}/${repositoryName}`,
      { locales: [] },
      { withCredentials: true }
    );
    return {data: response.data.message, success: response.data.success};
  } catch (error) {
    console.log("Save repository locales error:", error);
    return undefined;
  }
};

export const saveLocalesList = async (
  owner: string,
  repositoryName: string,
  data: LocalesList[],
  commitMessage: string
) => {
  try {
    const response = await axios.post(
      `/locale/save/${owner}/${repositoryName}/${commitMessage}`,
      { locales: data },
      { withCredentials: true }
    );

    return response.data.message;
  } catch (error) {
    console.log("Save repository locales error:", error);
    return undefined;
  }
};
