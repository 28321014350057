import axios from "axios";
import { create } from "zustand";
import { RepoList } from "../types/repositoryes";
import { persist, createJSONStorage } from "zustand/middleware";

interface AuthState {
  repo: RepoList[] | null;
  getRepositories: () => Promise<RepoList[] | null>;
  clearStore: () => void;
}

export const useRepoStore = create(
  persist<AuthState>(
    (set) => ({
      repo: null,
      getRepositories: async () => {
        try {
          const response = await axios.get("/repo/repositories", {
            withCredentials: true,
          });
          set({ repo: response.data });
          return response.data
        } catch (error) {
          console.error("Get Repo error:", error);
        }
      },
      clearStore: () => {
        set({ repo: null });
      },
    }),
    {
      name: "repo-storage",
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);

export default useRepoStore;
