import React, { useMemo, useCallback, FC, useEffect, useState } from "react";
import { DyTextarea } from "./shadcn/dynamic-textarea";
import { LocalesList } from "../types/Locales";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/shadcn/table";
import { Button } from "./shadcn/button";
import LangBlock from "./LangBlock";
import { Input } from "./shadcn/input";
import { isMobile } from "react-device-detect";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/shadcn/accordion";
import { Label } from "./shadcn/label";
import { flag } from "country-emoji";
import { ChevronDown, ChevronUp } from "lucide-react";
import _ from "lodash";
import ContentIframe from "./Iframe";
import { JsonEditorActions } from "./JsonEditorActions";

/**
 * Component Props Definition
 * - localesList: List of locales for translations
 * - onUpdate: Callback function when the JSON is updated
 */
interface JsonEditorFormProps {
  localesList: LocalesList[];
  onUpdate: (updatedJson: LocalesList[]) => void;
  onSave: (updatedJson: LocalesList[], commitMessage: string) => void;
  hasCommits?: boolean;
}

/**
 * JsonEditorForm Component
 *
 * This component provides an interface for users to interactively
 * edit and manage JSON translations across multiple locales.
 * It offers search functionality and supports nested JSON structures.
 * The component also integrates mobile-specific UI patterns for
 * enhanced usability on mobile devices.
 *
 * Props:
 * - localesList: An array of all available locales with their content.
 * - onUpdate: A callback function to handle updates to the JSON content.
 * - onSave: A callback function to handle saving the JSON content.
 * - hasCommits: A boolean indicating whether there are any commits on the current branch.
 *
 * State:
 * - editedJson: Represents the current state of the JSON content being edited.
 * - searchQuery: Current string in the search input.
 *
 * Functions:
 * - handleInputChange: Handles changes to any input and updates the state.
 * - renderInputs: A recursive function to generate input fields based on the JSON structure.
 * - filteredInputs: Filters the rendered inputs based on the search query.
 * - handleSubmit: Handles the form submission and triggers the onUpdate callback.
 */
const JsonEditorForm: FC<JsonEditorFormProps> = ({
  localesList,
  onUpdate,
  onSave,
  hasCommits = false,
}) => {
  // Local state for edited JSON content and search functionality
  const [editedJson, setEditedJson] =
    React.useState<LocalesList[]>(localesList);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [expandedKeys, setExpandedKeys] = React.useState<
    Record<string, boolean>
  >({});
  const [areAllExpanded, setAreAllExpanded] = useState(false);
  const [renderedKeyPaths, setRenderedKeyPaths] = useState<Set<string>>(
    new Set()
  );

  // Define includesSearchTerm using useCallback at the top level of the component
  const includesSearchTerm = useCallback(
    (keyPath: string, value: any): boolean => {
      const searchLower = searchQuery.toLowerCase();
      // Check if the key path includes the search term
      if (keyPath.toLowerCase().includes(searchLower)) {
        return true;
      }
      // If the value is a string, check if it includes the search term
      if (
        typeof value === "string" &&
        value.toLowerCase().includes(searchLower)
      ) {
        return true;
      }
      // If the value is an object, recursively check each nested key-value pair
      if (typeof value === "object" && value !== null) {
        return Object.entries(value).some(([nestedKey, nestedValue]) => {
          const nestedKeyPath = `${keyPath}.${nestedKey}`;
          return includesSearchTerm(nestedKeyPath, nestedValue);
        });
      }
      return false;
    },
    [searchQuery]
  );

  // Hook to initialize the expandedKeys state.
  // This effect sets the initial expansion state of all keys in the JSON object to false (collapsed).
  useEffect(() => {
    if (editedJson.length > 0) {
      // Ensure there is data to work with
      const initialKeys = getAllKeys(editedJson[0].content);
      const initialExpandedState = initialKeys.reduce<Record<string, boolean>>(
        (acc, key) => ({ ...acc, [key]: false }),
        {}
      );
      setExpandedKeys(initialExpandedState);
    }
  }, [editedJson]); // Only re-run the effect if editedJson changes

  /**
   * Callback to toggle the expansion state of a single key or all keys.
   */
  const toggleKeyExpansion = useCallback(
    (event: React.MouseEvent, key?: string) => {
      event.preventDefault();

      // If a specific key is provided, toggle only that key.
      if (key) {
        setExpandedKeys((prevKeys) => ({ ...prevKeys, [key]: !prevKeys[key] }));
      } else {
        // If no key is provided, toggle the expansion state of all keys.
        setExpandedKeys((prevKeys) => {
          const allExpanded = !areAllExpanded;
          setAreAllExpanded(allExpanded);
          return Object.fromEntries(
            Object.keys(prevKeys).map((k) => [k, allExpanded])
          );
        });
      }
    },
    [areAllExpanded]
  );

  /**
   * Handle input changes for specific translation key and locale
   * Updates the respective translation in the editedJson state
   */
  const handleInputChange = useCallback(
    (key: string, value: any, locale: LocalesList) => {
      setEditedJson((currentJson) =>
        currentJson.map((item) => {
          if (item !== locale) return item;

          const updatedContent = { ...item.content };
          let currentObj: Record<string, any> = updatedContent;

          const keys = key.split(".");
          keys.forEach((k, idx) => {
            if (idx === keys.length - 1) {
              currentObj[k] = value;
            } else {
              currentObj[k] = currentObj[k] || {};
              currentObj = currentObj[k];
            }
          });

          return { ...item, content: updatedContent };
        })
      );
    },
    []
  );

  /**
   * Recursive function to render input fields for JSON data
   * Allows for nested JSON structures to be represented as individual input fields
   */
  const renderInputs = useCallback(
    (
      data: Record<string, any>,
      parentKeyPath = "",
      localeIndex: number,
      localRenderedKeyPaths: Set<string> = new Set<string>()
    ) => {
      const elements: JSX.Element[] = [];
      // Initialize localRenderedKeyPaths if not provided
      localRenderedKeyPaths = localRenderedKeyPaths || new Set();

      Object.entries(data).forEach(([key, value]) => {
        const keyPath = parentKeyPath ? `${parentKeyPath}.${key}` : key;
        const uniqueKey = `locale-${localeIndex}-${keyPath}`;

        if (localRenderedKeyPaths.has(keyPath)) {
          return;
        }

        // console.log(`Checking input for keyPath: ${keyPath}`);
        if (
          !localRenderedKeyPaths.has(keyPath) &&
          (searchQuery.length === 0 || includesSearchTerm(keyPath, value))
        ) {
          localRenderedKeyPaths.add(keyPath);
          // Mark this keyPath as rendered

          // Add logic to push to elements array
          // Check if value is an object and recurse if necessary
          if (
            typeof value === "object" &&
            value !== null &&
            !Array.isArray(value)
          ) {
            // const nestedElements = renderInputs(value, keyPath, localeIndex, localRenderedKeyPaths);
            const nestedElements = renderInputs(
              value,
              `${keyPath}`,
              localeIndex,
              localRenderedKeyPaths // Pass the set down to the recursive call
            );

            // const nestedElements = renderInputs(value, `${inputKey}.`);

            // if (nestedElements.length > 0 && includesSearchTerm(key, value)) {
            if (nestedElements.length > 0) {
              elements.push(
                <React.Fragment key={uniqueKey}>
                  {isMobile ? (
                    <Accordion type="single" collapsible>
                      <AccordionItem value={keyPath}>
                        <AccordionTrigger>
                          {stringSeparator(key)}
                        </AccordionTrigger>
                        <AccordionContent>{nestedElements}</AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  ) : (
                    nestedElements
                  )}
                </React.Fragment>
              );
            }
          } else if (includesSearchTerm(keyPath, value)) {
            // const row = (
            elements.push(
              <React.Fragment key={uniqueKey}>
                {isMobile ? (
                  <div className="grid items-center gap-1.5 m-2">
                    <Label
                      className={"capitalize bg-blue-300/20 py-4 px-2 rounded"}
                    >
                      {key}
                    </Label>
                    {editedJson.map((locale, idx) => {
                      const langName = locale.metadata.name.split(".")[0];
                      const displayName = langName === "en" ? "UK" : langName;

                      return (
                        <div key={idx}>
                          <span className={"text-sm text-gray-400 ml-1"}>
                            {flag(displayName)} {langName}
                          </span>
                          <DyTextarea
                            className="mb-2"
                            value={String(
                              getValueFromKey(keyPath, locale.content)
                            )}
                            onChange={(e) =>
                              handleInputChange(keyPath, e.target.value, locale)
                            }
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <TableRow>
                    <TableCell className="flex gap-1.5">
                      <button
                        onClick={(event) => toggleKeyExpansion(event, keyPath)}
                      >
                        {expandedKeys[keyPath]
                          ? keyPath
                          : truncateString(keyPath)}
                      </button>

                      <ContentIframe keyPath={keyPath} />
                    </TableCell>
                    {editedJson.map((locale, idx) => (
                      <TableCell key={idx}>
                        <DyTextarea
                          className="mb-2"
                          value={String(
                            getValueFromKey(keyPath, locale.content)
                          )}
                          onChange={(e) =>
                            handleInputChange(keyPath, e.target.value, locale)
                          }
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                )}
              </React.Fragment>
            );
            // elements.push(row);
          }
        }
      });

      return elements;
    },
    [
      searchQuery.length,
      includesSearchTerm,
      editedJson,
      expandedKeys,
      handleInputChange,
      toggleKeyExpansion,
    ]
  );

  /**
   * Filters the input elements based on the search query
   */
  const filteredInputs = useMemo(() => {
    const localRenderedKeyPaths = new Set<string>(); // Initialize once here
    return localesList.flatMap((locale, index) =>
      renderInputs(locale.content, "", index, localRenderedKeyPaths)
    );
  }, [localesList, renderInputs]);  

  /**
   * Handles the form submission
   * Invokes the onUpdate callback prop with the edited JSON
   */
  const handleSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onUpdate(editedJson);
    },
    [onUpdate, editedJson]
  );

  /**
   * Handles the form submission
   * Invokes the onSave callback prop with the edited JSON
   */
  // const handleSave = useCallback(
  //   (e: React.MouseEvent) => {
  //     e.preventDefault();
  //     onSave(editedJson, commitMessage);
  //   },
  //   [onSave, editedJson]
  // );

  const handleSave = useCallback((e: React.MouseEvent, commitMessage: string) => {
    e.preventDefault();
    onSave(editedJson, commitMessage);
  }, [onSave, editedJson]);

  return (
    <form>
      <div className="mb-4">
        <Input
          type="text"
          placeholder="Search by Translation Key or Text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      {isMobile ? (
        filteredInputs
      ) : (
        <Table>
          <TableHeader className="border-b-2">
            <TableRow>
              <TableHead className="py-4 w-[50px]">
                <div className="flex gap-2 items-center">
                  <p>Translation Key</p>
                  <Button
                    className="rounded-full"
                    variant={"ghost"}
                    onClick={(event) => toggleKeyExpansion(event)}
                    size={"icon"}
                  >
                    {areAllExpanded ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </Button>
                </div>
              </TableHead>
              {editedJson.map((locale, idx) => (
                <TableHead className="py-4" key={idx}>
                  <LangBlock lang={locale.metadata.name} />
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>{filteredInputs}</TableBody>
        </Table>
      )}
      <JsonEditorActions onSave={handleSave} onPublish={handleSubmit} hasCommits={hasCommits} />
    </form>
  );
};

/**
 * Utility function to truncate long translation keys for better display
 *
 * @param input - The translation key to truncate
 * @param maxLength - The threshold beyond which the key is truncated
 */
const truncateString = (input: string, maxLength: number = 3): string => {
  const parts = input.split(".");
  return parts.length >= maxLength
    ? `${parts[0]}...${parts[parts.length - 1]}`
    : input;
};

/**
 * Utility function to format camelCase strings into a more readable format.
 * Transforms "testString" to "Test String" by separating capitalized words and ensuring the first word is capitalized.
 *
 * @param input - The camelCase string to be formatted.
 * @returns Formatted string with separated words and proper capitalization.
 */
const stringSeparator = (input: string): string => {
  return input
    .split("")
    .map((char, index) => {
      if (index === 0) {
        return char.toUpperCase();
      }
      // Add a space before an uppercase character
      return char.toUpperCase() === char ? ` ${char}` : char;
    })
    .join("");
};

/**
 * Recursively collects all the keys from a nested JSON object.
 * This function traverses through each level of the object, accumulating the keys in dot notation.
 * For example, for the object { a: { b: 1 } }, it would produce ['a', 'a.b'].
 *
 * @param data - The JSON object from which to extract the keys.
 * @param prefix - The accumulated key path for nested objects (used for recursive calls).
 * @returns An array of strings representing all keys in the object in dot notation.
 */
const getAllKeys = (data: Record<string, any>, prefix = ""): string[] => {
  return Object.entries(data).reduce<string[]>((keys, [key, value]) => {
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      return [...keys, newKey, ...getAllKeys(value, newKey), String(value)];
    } else {
      return [...keys, newKey, String(value)];
    }
  }, []);
};

const getValueFromKey = (key: string, data: Record<string, any>) => {
  return key.split(".").reduce((obj, k) => obj?.[k], data);
};

export default JsonEditorForm;
