import React, { useEffect, useState } from "react";
import Wrap from "../components/Wrap";
import Card from "../components/ui/Card";
import { Link, useParams } from "react-router-dom";
import useAuthStore from "../services/AuthService";
import Header from "../components/layouts/Header";
import { LocalesList } from "../types/Locales";
import JsonEditorForm from "../components/JsonEditorForm";
import Spinner from "../components/ui/Spinner";
import {
  getLocalesList,
  saveLocalesList,
  updateLocalesList,
} from "../services/LocalizeService";
import { useToast } from "../components/shadcn/toast/use-toast";
import { Toaster } from "../components/shadcn/toast/toaster";
import { ToastAction } from "../components/shadcn/toast/toast";
import { tr } from "date-fns/locale";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/shadcn/dialog"


const EditPage: React.FC = () => {
  const { user } = useAuthStore();
  const { owner, repo } = useParams();
  const [locales, setLocales] = useState<LocalesList[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCommits, setHasCommits] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      if (owner && repo) {
        const response = await getLocalesList(owner, repo);
        setIsLoading(false);
        if (!response) return setIsError(true);
        setIsError(false);
        setLocales(response.data);
        setHasCommits(response.hasCommits);
      }
    };

    getData();
  }, [owner, repo]);

  const handleUpdateJson = async (updatedJson: LocalesList[]) => {
    setLocales(updatedJson);

    if (owner && repo) {
      setIsLoading(true);

      const response = await updateLocalesList(owner, repo);
      console.log(response);
      setIsLoading(false);
      if (response?.success) {
        toast({
          description: "Pull request successfully created.",
          action: (
            <ToastAction asChild altText="Open github pull request page">
              <Link to={response.data} target="_blank">
                Open
              </Link>
            </ToastAction>
          ),
        });
        setHasCommits(false);
      } else {
        toast({
          variant: 'destructive',
          description: `An error occurred while creating the pull request. "${response?.data}"`,
        });
      }
    }
  };

  const handleSaveJson = async (updatedJson: LocalesList[], commitMessage: string) => {
    console.log(commitMessage);
    setLocales(updatedJson);
    if (owner && repo) {
      setIsLoading(true);

      const response = await saveLocalesList(owner, repo, updatedJson, commitMessage);
      setIsLoading(false);
      if (response) {
        toast({
          description:
            "The data was successfully saved to a separate branch in github",
          action: (
            <ToastAction asChild altText="Open github branch page">
              <Link to={response} target="_blank">
                Open
              </Link>
            </ToastAction>
          ),
        });
        setHasCommits(true);
      } else {
        toast({
          description: "An error occurred while save branch.",
        });
      }
    }
  };

  return (
    <>
      <Header user={user} repositoryName={repo} owner={owner} />
      <Wrap>
        <Card>
          <div>
            {locales && locales.length > 0 && !isLoading && (
              <div>
                <JsonEditorForm
                  localesList={locales}
                  onUpdate={handleUpdateJson}
                  onSave={handleSaveJson}
                  hasCommits={hasCommits}
                />
              </div>
            )}
          </div>
          <div className={"flex flex-col"}>
            {locales.length == 0 && !isLoading && (
              <div className={"flex w-full justify-center"}>
                <span>There are no localization files yet</span>
              </div>
            )}
            {isLoading && (
              <div
                className={
                  "flex flex-col items-center gap-4 w-full justify-center"
                }
              >
                <Spinner className="w-8 h-8" />
                <span className={"text-gray-400 uppercase"}>Loading...</span>
              </div>
            )}
          </div>
          <Toaster />
        </Card>
      </Wrap>
    </>
  );
};

export default EditPage;
