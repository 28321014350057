import axios from "axios";
import { create } from "zustand";
import { User } from "../types/user";
import { D } from "@mobily/ts-belt";

interface AuthState {
  user: User | undefined;
  checkAuthentication: () => Promise<User | void>;
  logout: () => Promise<void>;
}

const useAuthStore = create<AuthState>((set) => ({
  user: undefined,

  checkAuthentication: async (): Promise<User | undefined> => {
    try {
      console.log("Checking authentication...");
      const response = await axios.get("/auth/check-auth", {
        withCredentials: true,
      });
      const userData = response.data.user as User;
      if (D.isEmpty(userData)) return undefined;
      set({ user: userData });
      return userData;
    } catch (error) {
      console.error("GitHub callback error:", error);
      return undefined;
    }
  },

  logout: async (): Promise<void> => {
    try {
      await axios.get("/auth/logout", {
        withCredentials: true,
      });
      set({ user: undefined });
    } catch (error) {
      console.log("Logout error: ", error);
      set({ user: undefined });
    }
  },
}));

export default useAuthStore;
