import React from "react";
import GitHubAuth from "../components/GitHubAuth";
import cx from "classnames";

const LoginPage: React.FC = () => {
  return (
    <div className={cx("flex justify-center items-center w-full sm:h-screen bg-[#F6F8FC]")}>
      <GitHubAuth />
    </div>
  );
};

export default LoginPage;
