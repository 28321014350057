import React, { useEffect, useState, useCallback, memo } from "react";
import { Link } from "react-router-dom";
import { Pencil, Github, RefreshCcw, Cog } from "lucide-react";

import useAuthStore from "../services/AuthService";
import useRepoStore from "../services/RepoService";
import Header from "../components/layouts/Header";
import Card from "../components/ui/Card";
import { Button } from "../components/shadcn/button";
import { cn } from "../lib/utils";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/shadcn/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/shadcn/tooltip";
import Wrap from "../components/Wrap";
import { Avatar, AvatarImage } from "../components/shadcn/avatar";
import ScreenSpinner from "../components/ui/ScreenSpinner";
import { useToast } from "../components/shadcn/toast/use-toast";
import { Toaster } from "../components/shadcn/toast/toaster";
import { ToastAction } from "../components/shadcn/toast/toast";
import { RepoList } from "../types/repositoryes";

interface RepoTableRowProps {
  item: RepoList;
}

const RepoTableRow = memo<RepoTableRowProps>(({ item }) => (
  <TableRow>
    <TableCell className="block sm:table-cell">
      {item.avatar ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div
                className={
                  "flex justify-center sm:justify-normal bg-slate-100 sm:bg-inherit py-2 sm:py-0 w-full sm:w-[40px]"
                }
              >
                <Link className={"w-[40px]"} to={item.ownerUrl} target="_blank">
                  <Avatar className="hover:animate-pulse">
                    <AvatarImage
                      src={item.avatar}
                      alt={`Avatar for ${item.owner}`}
                    />
                  </Avatar>
                </Link>
              </div>
            </TooltipTrigger>
            <TooltipContent>{item.owner}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <span>{item.owner}</span>
      )}
    </TableCell>
    <TableCell className="block sm:table-cell">
      <span className="sm:hidden font-bold">Repository: </span>
      {item.name}
    </TableCell>
    <TableCell className="block sm:table-cell">
      <span className="sm:hidden font-bold">Description: </span>
      {item.description ? (
        item.description
      ) : (
        <span className={cn("text-gray-500")}>No description...</span>
      )}
    </TableCell>
    <TableCell className="flex flex-col sm:flex-row gap-4 justify-end">
      <Button asChild variant="outline">
        <Link to={`edit/${item.owner}/${item.name}`}>
          <Pencil className="mr-2 h-4 w-4" aria-hidden="true" /> Edit
        </Link>
      </Button>
      <Button asChild variant="outline">
        <Link to={item.repoUrl} target="_blank">
          <Github className="mr-2 h-4 w-4" aria-hidden="true" /> GitHub
        </Link>
      </Button>
    </TableCell>
  </TableRow>
));

RepoTableRow.displayName = "RepoTableRow";

const HomePage: React.FC = () => {
  const { user } = useAuthStore();
  const { repo, getRepositories } = useRepoStore();
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const getRepoData = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await getRepositories();
      if (!data) {
        toast({
          duration: 10000,
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description:
            "There was a problem with a request to get a list of repositories.",
          action: (
            <ToastAction onClick={getRepoData} altText="Try again">
              Retry
            </ToastAction>
          ),
        });
      }
    } catch (error) {
      console.error("Failed to fetch repositories:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getRepositories, toast]);

  useEffect(() => {
    if (!repo) {
      getRepoData();
    }
  }, [repo, getRepoData]);

  return (
    <>
      {user && <Header user={user} />}
      <Wrap>
        <Card className={cn("w-full")}>
          <div className="flex gap-2 flex-col sm:flex-row">
            <Button variant={"outline"} onClick={() => getRepoData()}>
              <RefreshCcw className={"w-4 h-4 mr-2"} />
              Update list
            </Button>
            <Button asChild variant={"outline"}>
              <Link
                to="https://github.com/settings/connections/applications/"
                target="_blank"
              >
                <Cog className={"w-4 h-4 mr-2"} />
                Change permissions
              </Link>
            </Button>
          </div>
          <Table className="block sm:table w-full">
            <TableHeader className="hidden sm:table-header-group">
              <TableRow>
                <TableHead className="w-[100px]">Owner</TableHead>
                <TableHead>Repository</TableHead>
                <TableHead>Description</TableHead>
                <TableHead className="text-right">Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="flex flex-col sm:table-row-group">
              {repo?.map((item, index) => (
                <RepoTableRow key={index} item={item} />
              ))}
            </TableBody>
          </Table>
          <Toaster />
          {isLoading && <ScreenSpinner />}
        </Card>
      </Wrap>
    </>
  );
};

export default HomePage;
