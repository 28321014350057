import axios from "axios";
import baseURL from "./config";
import { logoutUser } from "../hooks/Auth";

// Setting the base URL for all axios requests
axios.defaults.baseURL = baseURL;

// Adding a Response Interceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized. Logging out...");
      await logoutUser();
    }
    return Promise.reject(error);
  }
);

export default axios;
