import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import { Button } from "../components/shadcn/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/shadcn/popover";
import { cn } from "../lib/utils";
import { Eraser, LayoutTemplate } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/shadcn/tooltip";

interface ContentIframeProps {
  keyPath: string;
  className?: string;
  url?: string;
}

const ContentIframe: FC<ContentIframeProps> = ({
  keyPath,
  className,
  url = "https://renover.klima-agence.lu/?dev",
}) => {
  const [content, setContent] = useState<string>("");
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const encodedUrl = encodeURIComponent(url);

  const getContent = async () => {
    try {
      console.log("start get content");
      setContent("");
      const response = await axios.get(
        `/screen/page/${encodedUrl}/${keyPath}`,
        { withCredentials: true }
      );
      console.log("response", response);
      response.data.foundText
        ? setContent(response.data.content)
        : setContent("No content found");
      setIsPopoverOpen(true);
    } catch (error) {
      console.error("Get datas error", error);
      setContent("No content found");
    }
  };

  const clearChache = async () => {
    try {
      console.log("start clear content");
      setContent("");
      const response = await axios.delete(
        `/screen/cache/delete/${encodedUrl}/${keyPath}`,
        { withCredentials: true }
      );
      console.log("response", response);
      setContent("No content found");
      return await getContent();
    } catch (error) {
      console.error("Get datas error", error);
      setContent("No content found");
    }
  };

  useEffect(() => {
    if (isPopoverOpen && content) {
      const element = document.querySelector(".highlighted-text");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [isPopoverOpen, content]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button onClick={() => getContent()} size={"icon"} variant={"ghost"}>
          <LayoutTemplate className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          className,
          "w-full h-full max-h-[600px] overflow-auto m-5"
        )}
      >
        {content ? (
          <>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={() => clearChache()}
                    variant={"outline"}
                    size={"sm"}
                  >
                    <Eraser className="h-4 w-4 mr-1" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side={"right"}>
                  <p>Clear cache</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <div
              id="htmlContentBlock"
              className=""
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default ContentIframe;
